import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {addAboutText, getAbout} from "../../api/About";
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";
import {Container} from "../../packages/my-theme/form/styled";
import {Title} from "../../packages/my-theme/styled";
import Section from "../../packages/my-theme/form/Section";
import ReactQuill from "react-quill"; // Usa ReactQuill per l'editor
import "react-quill/dist/quill.snow.css"; // Importa lo stile per Quill
import styled from "styled-components";
import AddButton from "../../packages/my-theme/form/AddButton";
import MessagePopup from "../../packages/my-theme/component/MessagePopup";

const StyledEditor = styled.div`
    .ql-container {
        display: block;
        width: 100%;
        padding: 8px;
        margin-bottom: 10px;
        box-sizing: border-box;
        font-size: 14px;
        color: white;
        border: none;
        border-bottom: 2px solid red;
        background: transparent;
        outline: none;
    }

    .ql-editor {
        min-height: 100px;
        resize: none;
        overflow: hidden;
        font-size: 14px;
        color: white;
    }

    .ql-editor p {
        margin: 0;
    }

    .ql-toolbar {
        display: block;
        width: 100%;
        padding: 8px;
        margin-bottom: 10px;
        box-sizing: border-box;
        font-size: 14px;
        color: white;
        border: none;
        border-bottom: 2px solid red;
        background: transparent;
        outline: none;
    }

    .ql-toolbar .ql-picker-label,
    .ql-toolbar .ql-picker-item,
    .ql-toolbar .ql-active {
        color: white;
    }

    .ql-toolbar .ql-picker {
        border: 1px solid #ccc;
    }

    .ql-toolbar .ql-stroke {
        stroke: white;
    }

    .ql-color-picker .ql-picker-item {
        background-color: #fff !important;
    }
`;

export default function About() {
  const navigate = useNavigate();
  const [about, setAbout] = useState([]);
  const [popup, setPopup] = useState({show: false, message: "", success: false});
  const [language, setLanguage] = useState("IT");
  const quillRefs = useRef([]);

  useEffect(() => {
    getAbout()
      .then((data) => {
        setAbout(data);
        quillRefs.current = data.map(() => React.createRef());
      })
      .catch((error) => {
        console.error("Error fetching about data:", error);
        setPopup({show: true, message: "Errore nel recupero dei dati!", success: false});
      });

    setLanguage("IT");
  }, []);

  const handleTextChange = (index, newText) => {
    const updatedAbout = [...about];
    updatedAbout[index].text = newText;
    setAbout(updatedAbout);
  };

  const handleSubmit = () => {
    const data = about.map((item, index) => ({
      language, text: item.text, view: 1, orderIndex: index + 1, aboutPhotoId: item.aboutPhotoId,
    }));

    addAboutText(data)
      .then((response) => {
        if (response.message === "Record inseriti con successo") {
          setPopup({show: true, message: "Inserimento avvenuto con successo!", success: true});
        }
      })
      .catch((error) => {
        console.log(error);
        setPopup({show: true, message: "Errore durante l'inserimento!", success: false});
      });
  };

  const handleRedirect = () => {
    navigate("/frontendData");
  };

  if (about.length === 0 || language === null) {
    return <LoadingSpinner/>;
  }

  return (<Container>
    <Title>About</Title>

    {about.map((item, index) => (<Section title="" key={index}>
      <StyledEditor>
        <ReactQuill
          value={item.text}
          onChange={(newText) => handleTextChange(index, newText)}
          ref={quillRefs.current[index]}
          placeholder="Inserisci testo"
        />
      </StyledEditor>
    </Section>))}

    <AddButton type="button" onClick={handleSubmit}>
      Submit
    </AddButton>
    <MessagePopup
      show={popup.show}
      message={popup.message}
      success={popup.success}
      onButtonClick={handleRedirect}
    />
  </Container>);
}
