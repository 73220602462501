import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {addContactData, getContactData} from "../../api/Contact";
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";
import {Container} from "../../packages/my-theme/form/styled";
import {Title} from "../../packages/my-theme/styled";
import Section from "../../packages/my-theme/form/Section";
import TextArea from "../../packages/my-theme/form/TextArea";
import AddButton from "../../packages/my-theme/form/AddButton";
import MessagePopup from "../../packages/my-theme/component/MessagePopup";
import ReactQuill from "react-quill";
import styled from "styled-components";

const StyledEditor = styled.div`
    .ql-container {
        display: block;
        width: 100%;
        padding: 8px;
        margin-bottom: 10px;
        box-sizing: border-box;
        font-size: 14px;
        color: white;
        border: none;
        border-bottom: 2px solid red;
        background: transparent;
        outline: none;
    }

    .ql-editor {
        min-height: 100px;
        resize: none;
        overflow: hidden;
        font-size: 14px;
        color: white;
    }

    .ql-editor p {
        margin: 0;
    }

    .ql-toolbar {
        display: block;
        width: 100%;
        padding: 8px;
        margin-bottom: 10px;
        box-sizing: border-box;
        font-size: 14px;
        color: white;
        border: none;
        border-bottom: 2px solid red;
        background: transparent;
        outline: none;
    }

    .ql-toolbar .ql-picker-label,
    .ql-toolbar .ql-picker-item,
    .ql-toolbar .ql-active {
        color: white;
    }

    .ql-toolbar .ql-picker {
        border: 1px solid #ccc;
    }

    .ql-toolbar .ql-stroke {
        stroke: white;
    }

    .ql-color-picker .ql-picker-item {
        background-color: #fff !important;
    }
`;

export default function Contact() {
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [text, setText] = useState(null);
  const [phone, setPhone] = useState(null);
  const [popup, setPopup] = useState({show: false, message: '', success: false});
  const quillRef = useRef(null); // Cambiato per supportare una sola ref

  useEffect(() => {
    getContactData()
      .then(data => {
        setEmail(data[0].email);
        setPhone(data[0].phone);
        setText(data[0].text);
      })
      .catch(error => {
        console.error("Error fetching contact data:", error);
        setPopup({show: true, message: 'Errore nel recupero dei dati!', success: false});
      });
  }, []);

  const handleSubmit = () => {
    addContactData({email, phone, text, view: 1, orderIndex: 1})
      .then(response => {
        if (response.message === "Record ContactData inseriti con successo") {
          setPopup({show: true, message: 'Inserimento avvenuto con successo!', success: true});
        }
      })
      .catch(error => {
        console.log(error);
        setPopup({show: true, message: 'Errore durante l\'inserimento!', success: false});
      });
  };

  const handleRedirect = () => {
    navigate('/frontendData');
  };

  if (email == null || phone == null) {
    return <LoadingSpinner/>;
  }

  return (
    <Container>
      <Title>Contact</Title>
      <Section title="">
        <TextArea
          placeholder="Inserisci email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextArea
          placeholder="Inserisci telefono"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <StyledEditor>
          <ReactQuill
            value={text}
            onChange={setText} // Modifica per usare direttamente setText
            ref={quillRef}
            placeholder="Inserisci testo"
          />
        </StyledEditor>
      </Section>
      <AddButton type="button" onClick={handleSubmit}>
        Submit
      </AddButton>
      <MessagePopup
        show={popup.show}
        message={popup.message}
        success={popup.success}
        onButtonClick={handleRedirect}
      />
    </Container>
  );
}
