import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";
import {Container} from "../../packages/my-theme/form/styled";
import {Title} from "../../packages/my-theme/styled";
import Section from "../../packages/my-theme/form/Section";
import AddButton from "../../packages/my-theme/form/AddButton";
import MessagePopup from "../../packages/my-theme/component/MessagePopup";
import {addCocktailTextData, getCocktailText} from "../../api/Cocktail";
import styled from "styled-components";
import ReactQuill from "react-quill";

const StyledEditor = styled.div`
    .ql-container {
        display: block;
        width: 100%;
        padding: 8px;
        margin-bottom: 10px;
        box-sizing: border-box;
        font-size: 14px;
        color: white;
        border: none;
        border-bottom: 2px solid red;
        background: transparent;
        outline: none;
    }

    .ql-editor {
        min-height: 100px;
        resize: none;
        overflow: hidden;
        font-size: 14px;
        color: white;
    }

    .ql-editor p {
        margin: 0;
    }

    .ql-toolbar {
        display: block;
        width: 100%;
        padding: 8px;
        margin-bottom: 10px;
        box-sizing: border-box;
        font-size: 14px;
        color: white;
        border: none;
        border-bottom: 2px solid red;
        background: transparent;
        outline: none;
    }

    .ql-toolbar .ql-picker-label,
    .ql-toolbar .ql-picker-item,
    .ql-toolbar .ql-active {
        color: white;
    }

    .ql-toolbar .ql-picker {
        border: 1px solid #ccc;
    }

    .ql-toolbar .ql-stroke {
        stroke: white;
    }

    .ql-color-picker .ql-picker-item {
        background-color: #fff !important;
    }
`;

export default function CocktailText() {
  const navigate = useNavigate();
  const [homeText, setHomeText] = useState(null);
  const [popup, setPopup] = useState({show: false, message: '', success: false});
  const quillRef = useRef(null); // Cambiato per supportare una sola ref

  useEffect(() => {
    getCocktailText()
      .then(data => {
        setHomeText(data.text.text); // Corretto l'accesso ai dati
      })
      .catch(error => {
        setPopup({show: true, message: 'Errore nel recupero dei dati!', success: false});
      });
  }, []);

  const handleSubmit = () => {
    addCocktailTextData({text: homeText, view: 1, language: "IT"})
      .then(response => {
        if (response.message === "Testo inserito con successo") {
          setPopup({show: true, message: 'Inserimento avvenuto con successo!', success: true});
        }
      })
      .catch(error => {
        console.log(error);
        setPopup({show: true, message: 'Errore durante l\'inserimento!', success: false});
      });
  };

  const handleRedirect = () => {
    navigate('/frontendData');
  };

  if (homeText === null) {
    return <LoadingSpinner/>;
  }

  return (<Container>
    <Title>Cocktail Text</Title>
    <Section title="">
      <StyledEditor>
        <ReactQuill
          value={homeText}
          onChange={setHomeText} // A questo punto ReactQuill restituisce il valore direttamente
          ref={quillRef} // Usato un singolo ref
          placeholder="Inserisci testo"
        />
      </StyledEditor>
    </Section>
    <AddButton type="button" onClick={handleSubmit}>
      Submit
    </AddButton>
    <MessagePopup
      show={popup.show}
      message={popup.message}
      success={popup.success}
      onButtonClick={handleRedirect}
    />
  </Container>);
}
